<template>
    <div ref="grecaptcha"></div>
</template>
<script>
export default {
    name: 'Recaptcha',
    data() {
        return {
            resolve: null,
            reject: null,
            recaptchaId: 0,
        };
    },
    mounted() {
        let recaptchaScript = document.createElement('script');

        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
        recaptchaScript.setAttribute('async', '');
        recaptchaScript.addEventListener('load', this.generateRecaptchaId);
        document.head.appendChild(recaptchaScript);
    },
    methods: {

        async generate() {
            
            window.grecaptcha.execute(this.recaptchaId);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        getToken(token) {
            
            window.grecaptcha.reset(this.recaptchaId);
            this.resolve(token);

        },
        getError() {
            this.reject();
        },
        generateRecaptchaId() {
            

            const options = {
                sitekey: '6LfYeMAZAAAAAN8KU5gANTzCmgenyUX4yrdUqi7j',
                size: 'invisible',
                callback: this.getToken,
                'error-callback': this.getError
            };


            window.grecaptcha.ready(() => {
                
                this.recaptchaId = window.grecaptcha.render(this.$refs.grecaptcha, options);
            });


        },

    }
}
</script>
