import { validate as validateCPF } from 'gerador-validador-cpf';
import { checkCreditCardType } from '@commons/helpers';

import moment from 'moment';

const validCPF = value => {
	let cpf = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/;
	return cpf.test(value) && validateCPF( value );
}

const isValidMobilePhone = value => {
	return !!value.match(/([0-9]{2})\s([0-9]{5}-[0-9]{4})/gi);
}

const isStrongPassword = value => {
	return !!value.match(/[0-9]/g) && //Numbers
            !!value.match(/[A-Z]/g) && //Uppercase
            !!value.match(/[a-z]/g); //Lowercase
}

const isValidDateBR = value => {
	return moment(value, 'DD/MM/YYYY', true).isValid();
}

const isValidCreditCard = value => {
	const creditCardType = checkCreditCardType(value);
	const cardNumberLength = value.replace(/ /g,'').length;

	if (!creditCardType) {
		return false;
	}

	if (creditCardType.includes('american_express') && cardNumberLength == 15) {
		return true;
	}

	if (creditCardType.includes('diners') && cardNumberLength == 14) {
		return true;
	}

	return creditCardType && cardNumberLength == 16;
}

const isValidCardExpiration = value => {
	const dateParts = value.split('/');
	if (dateParts.length !== 2) {
		return false;
	}

	const month = +dateParts[0];
	let year = +dateParts[1];

	if (month < 1 || month > 12) {
		return false;
	}

	if (year < 100) {
		year += 2000;
	}

	const todayDate = new Date();
	const cardExpirationDate = new Date(year, month - 1, 0);

	return cardExpirationDate.valueOf() > todayDate.valueOf();
}

export {
	validCPF,
	isValidMobilePhone,
	isStrongPassword,
	isValidDateBR,
	isValidCreditCard,
	isValidCardExpiration
}
