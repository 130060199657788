<template>
	<div
		class="ui-image"
		:title="alt"
	>
		<span
			class="ui-image-dummy"
			:style="imageRatio"
		/>

		<img
			src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACRJREFUeNpi/P//PwM1ARMDlcGogaMGjho4auCogUPFQIAAAwBpCwMl2zY1TwAAAABJRU5ErkJggg=="
			:style="properties"
			alt=""
		>
	</div>
</template>

<script>
export default {
	name: 'UIImage',
	props:{
		size: {
			type: String,
			default: '100:100',
			useDefaultForNull: true
		},
		src: {
			type: String,
			default: 'no_image.png',
			useDefaultForNull: true
		},
		alt: {
			type: String,
			default: '',
			useDefaultForNull: true
		},
		bgsize: {
			type: String,
			default: 'cover',
			useDefaultForNull: true
		},
		external: {
			type: Boolean,
			default: false,
			useDefaultForNull: true
		},
	},
	computed: {
		imageRatio(){
			let s = this.size.split(':').map(v=>parseInt(v));
			return `padding-top: ${(s[1] / s[0]) * 100}%`;
		},
		properties(){
			let p = { backgroundSize: this.bgsize };

			let isURL = this.src.match("https?://");

			if (isURL) {
				p.backgroundImage = `url(${this.src})`;
			}

			if (this.src !== 'none' && this.src != "" && ! isURL) {
				p.backgroundImage = `url(${require(`./../assets/${this.src}`)})`;
			}

			return p;
		}
	},
}
</script>

<style lang="scss" scoped>
.ui-image {
	display: inline-block;
	position: relative;
	width: 100%;

	&-dummy {
		height: 0;
		width: 100%;
		display: block;
	}

	img {
		border: 0 none;
		pointer-events: none;
		width: 100%;
		height: 100%;
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: center center;
	}
}
</style>
