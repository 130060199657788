<template>
    <div class="container">
        <div class="columns pt-2">
            <div class="column is-offset-3 is-6 has-text-centered mt-4">
                <button class="button btn-fale-conosco" @click="showHelpBox = !showHelpBox">
                    Fale Conosco
                </button>
            </div>
        </div>

        <transition>
            <div v-if="showHelpBox" class=" py-5 help-box is-flex is-flex-direction-column pl-6 is-relative">
                <p class="is-size-7 has-text-weight-bold">
                    Atendimento de Segunda a Sexta-Feira exclusivamente pelo WhatsApp:
                </p>

                <div class="clickable py-3 is-flex is-flex-direction-row is-align-items-center" @click="sendToWhatsApp">
                    <UIImage class="whats-icon mr-1" size="20:20" src="whatsapp.svg" />
                    <p class="is-size-7 has-text-weight-bold">
                        (11) 3298 7730
                    </p>
                </div>

                <p class="is-size-7 has-text-weight-bold">
                    HORÁRIOS:
                </p>
                <p v-if="$store.state.ie.sigla == 'uns'" class="is-size-7 has-text-weight-bold">
                    Suporte geral e rematrícula: 8h às 19h.
                </p>
                <p v-else class="is-size-7 has-text-weight-bold">
                    Suporte geral e rematrícula: 8h às 19:30.
                </p>
                <p class="is-size-7 has-text-weight-bold">
                    Trancamento e cancelamento: 8h às 18h.
                </p>
                <button class="py-0 px-0 mr-5 my-3 button btn-close-help-box is-rounded is-primary is-outlined"
                    @click="showHelpBox = !showHelpBox">
                    Fechar
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import UIImage from '@components/UIImage';

export default {

    name: 'FaleConosco',
    components:{
		UIImage,
	},
    data(){
        return {
            showHelpBox: false,
        };
    }, 
    methods: {
        sendToWhatsApp() {
			window.open('https://api.whatsapp.com/send?phone=551132987730&text=Oi Estudante! Clique em enviar para falar conosco!', '_blank');
		}
    }
}
</script>

<style lang="scss" scoped>

.btn-fale-conosco,
.btn-fale-conosco:focus {
	background: #ffffff;
	box-shadow: 1px 7px 7px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	border: none;
}

.btn-close-help-box {
	width: 25%;
	margin-left: auto;
	position: absolute;
	right: 0;
	bottom: 0;
}

.clickable {
	cursor: pointer;
}

.help-box {
	box-shadow: 1px 7px 4px rgba(0, 0, 0, 0.25);
}

.whats-icon {
	width: 27px;
}


@media (max-width: 500px) {
	.help-box {
		padding: 30px !important;
	}

    .btn-close-help-box {
        width: 100%;
        position: initial;
        right: 0;
        bottom: 0;
    }
}


.fade-enter-active {
	transition: opacity 1s;
}

.fade-leave-active {
	transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
	opacity: 0;
}

</style>