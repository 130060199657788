<script>
import { mapMutations } from 'vuex';
import UIInput from '@components/uiinput';
import Recaptcha from '@components/Recaptcha';
import { required } from "vuelidate/lib/validators";
import { validCPF } from '@commons/validations';
import http from '@commons/http';
import FaleConosco from '@components/login/FaleConosco';

export default {
	name: 'LoginRecover',
	components: {
		UIInput,
		Recaptcha,
		FaleConosco,
	},
	data() {
		return {
			notification: false,
			form: {
				cpf: "",
				captcha: ""
			},
			loading: false,
		}
	},
	validations: {
		form: {
			cpf: {
				required,
				validCPF,
			},
		}
	},
	methods: {
		...mapMutations('senha', [
			'setEmailMasked',
			'setCpf',
			'setMetodos',
		]),
		goto() {
			this.$router.push({ name: 'login.home' });
		},
		recoverMethods() {
			this.$router.push({ name: 'login.recover_methods' });
		},
		async handleRecoverSubmit() {
			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) return;

			try {
				this.form.captcha = await this.$refs.grecaptcha.generate();
			} catch (error) {
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Recaptcha inválido!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
				return;
			}

			let data_params = new URLSearchParams(Object.entries(this.form)).toString();
			this.loading = true;
			http.get('/v1/auth/senha/metodos?' + data_params).then(({ data }) => {

				this.loading = false;
				this.form.captcha = '';

				if (this.notification) this.notification.close();

				this.setEmailMasked(data.email);
				this.setCpf(data.cpf);
				this.setMetodos(data.metodos);

				this.recoverMethods();

			}, () => {
				this.form.captcha = '';
				this.loading = false;
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `O CPF informado não foi encontrado`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
			});
		}
	},
}
</script>


<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Que bom ter você na {{ $store.state.ie.shortname }}!
			</h2>
			<h3 class="subtitle">
				Para redefinir a sua senha, informe seu CPF abaixo e clique em Confirmar.
			</h3>
		</div>

		<div class="columns mt-5">
			<div class="column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd">
				<form
					novalidate
					class="form"
					@submit.prevent="handleRecoverSubmit"
				>
					<div class="columns is-multiline">
						<div class="column is-12">
							<UIInput
								id="cpf"
								v-model="form.cpf"
								v-mask="'###.###.###-##'"
								label="CPF"
								placeholder="Seu CPF"
								:validation="$v.form.cpf"
								:error="{
									invalid: '*CPF inválido.',
									required: '*Campo obrigatório.'
								}"
								required
							/>
						</div>

						<div class="column is-12 has-text-centered mt-4">
							<button class="button is-primary is-rounded">
								Confirmar
							</button>
						</div>

						<div class="column is-12 has-text-centered mt-0">
							<button
								class="button is-primary is-rounded is-outlined"
								@click="goto"
							>
								Voltar para o login
							</button>
						</div>
					</div>
				</form>
				<FaleConosco />
			</div>
		</div>

		<b-loading
			v-model="loading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<Recaptcha ref="grecaptcha" />
	</div>
</template>

<style scoped>
.notification {
	background-color: transparent;
}
</style>
